// ======= STATES
export const RESET_GENERAL_STATES = 'RESET_GENERAL_STATES';

// ======= PROGRESS
export const ACTION_IN_PROGRESS = 'ACTION_IN_PROGRESS';
export const ACTION_FINISHED = 'ACTION_FINISHED';

// ======= ALERT
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_ALERT = 'SET_ALERT';

// ======= LANGUAGE
export const LANG_EN = 'LANG_EN';
export const LANG_FR = 'LANG_FR';
