import { BsFacebook, BsFillEnvelopeAtFill, BsFillEnvelopeFill, BsInstagram, BsLinkedin } from 'react-icons/bs';

export const SOCIAL = [
    {
        icon: <BsFacebook />,
        url: 'https://www.facebook.com/profile.php?id=100063829613645',
    },
    {
        icon: <BsLinkedin />,
        url: 'https://www.linkedin.com/company/social-innovation-um6p/',
    },
    {
        icon: <BsInstagram />,
        url: 'https://www.instagram.com/social_innovationlab/',
    },
    {
        icon: <BsFillEnvelopeFill />,
        url: 'mailto:sil@um6p.ma',
    },
];

export const CONTACT = [
    {
        icon: <BsFillEnvelopeAtFill />,
        text: 'sil@um6p.ma',
    },
];
