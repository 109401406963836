export const NAVBAR_PAGES_FR = [
    {
        title: 'À propos de nous',
        id: 'a-propos-de-nous',
    },
    {
        title: 'Notre vision',
        id: 'notre-vision',
    },
    {
        title: 'Nos Chaînes de Valeur',
        id: 'nos-chaines-de-valeur',
    },
    {
        title: 'Comment ça marche',
        id: 'comment-ca-marche',
    },
    {
        title: "VCU-SIL À aujourd'hui",
        id: 'vcu-sil-a-aujourd-hui',
    },
];

export const NAVBAR_PAGES_EN = [
    {
        title: 'About us',
        id: 'about-us',
    },
    {
        title: 'Our vision',
        id: 'our-vision',
    },
    {
        title: 'Our value chains',
        id: 'our-value-chains',
    },
    {
        title: 'How it works',
        id: 'how-it-works',
    },
    {
        title: 'VCU SIL up to date',
        id: 'vcu-sil-up-to-date',
    },
];
