export const PAGES = {
    landing: {
        title: 'VCU | Bienvenu',
        url: '/',
    },
    notFound: {
        title: 'VCU | Oops!',
        url: '*',
    },
};
